import { forwardRef } from 'react';
import api, { useTable } from 'api';
import { endpoints } from 'endpoints.config';
import './ConnectionsPage.scss';
import { FoldingCube } from 'better-react-spinkit';
import { useTheme } from '@emotion/react';
import { ItemConnection, ItemConnectionModel } from './items/ItemConnection';
import Pagination from 'components/pagination/Pagination';
type Props = {
    back?: () => void;
};

export const PendingConnectionsRequests = forwardRef((props: Props, ref) => {
    const { colors } = useTheme();
    const transactionsTable = useTable<ItemConnectionModel, any>({
        url: `${endpoints.networkmodule.getNetworkConnections}?ConnectionStatus=PENDING`,
        bClearFilters: true,
    });

    return (
        <div className="list-container-networks">
            {transactionsTable?.loading && (
                <div className="PageLoader">
                    <FoldingCube color={colors.first} size={80} />
                </div>
            )}
            {transactionsTable?.data?.details?.listData &&
            transactionsTable?.data?.details?.listData?.length > 0 ? (
                transactionsTable?.data?.details?.listData.map(
                    (item: ItemConnectionModel, index: number) => (
                        <ItemConnection
                            key={item.receiverId + '_' + index}
                            networkUser={item}
                            showConnectionRequest={(itemClicked: ItemConnectionModel) => {
                                //setConnectionDetail(itemClicked);
                            }}
                            refreshList={transactionsTable?.reload}
                        />
                    )
                )
            ) : transactionsTable?.loading ? (
                <></>
            ) : (
                <div className="no-results-message">No results found</div>
            )}
            <Pagination table={transactionsTable} />
        </div>
    );
});
