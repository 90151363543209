import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    AssetPairsDetails,
    AvailableOnOptions,
    PriceInfo,
    selectAllCryptoPrices,
} from 'reducers/cryptoPrices';
import { uniq } from 'lodash';
import { FormikHelpers } from 'formik';
import { isAxiosErrorHandled } from 'api';
import { SelectOption } from '@avamae/formbuilder/dist/FormSingleSelectField';
import { ERROR_CODES, getErrorMessage } from 'errors';
import { toCamelCase } from 'helpers/formatFormFieldNames';
import { PaymentGatewayMidConfig, Rules } from './model';
import { QuickCoinBuy } from './QuickCoinBuy';
import { RadioButton } from 'components/radiobuttons/radiobuttons';
import { QuickCoinSell } from './QuickCoinSell';
import { selectUserInfo } from 'reducers/componentResources';

export const QuickCoinPage = () => {
    const prices = useSelector(selectAllCryptoPrices);
    const [cryptoOptions, setCryptoOptions] = useState<SelectOption[]>([]);
    const [staticPrices, setStaticPrices] = useState<AssetPairsDetails | null>(null);
    const [transactionType, setTransactionType] = useState<'Buy' | 'Sell'>('Buy');
    const [midConfigs, setMidConfigs] = useState<PaymentGatewayMidConfig[] | null>(null);
    const [showBuySellButtons, setShowBuySellButtons] = useState(true);
    const hideBuySellButtons = useCallback(() => setShowBuySellButtons(false), []);

    const userInfo = useSelector(selectUserInfo);

    useEffect(() => {
        if (Object.keys(prices).length !== Object.keys(staticPrices ?? []).length) {
            setStaticPrices(prices);
        }
    }, [staticPrices, prices]);

    const [rules, _setRules] = useState<
        (PriceInfo & { asset: string; pairedAsset: string }) | null
    >(null);

    useEffect(() => {
        const availableCryptos = uniq(
            Object.keys(prices)
                .filter((ticker) =>
                    transactionType === 'Sell'
                        ? prices[ticker]?.availableOn?.includes(AvailableOnOptions.QuickCoinSell)
                        : prices[ticker]?.availableOn?.includes(AvailableOnOptions.QuickCoinBuy)
                )
                .map((pricePair: string) => pricePair.split('/')[0])
        );
        setCryptoOptions(availableCryptos.map((ticker) => ({ label: ticker, value: ticker })));
    }, [prices, transactionType]);

    const updateRules = useCallback(
        (asset: string, pairedAsset: string) => {
            if (midConfigs && midConfigs?.length > 0 && staticPrices?.[`${asset}/USD`]) {
                let maxPrice = staticPrices[`${asset}/USD`].maxPrice;
                const gatewayConfig =
                    midConfigs.find((config) => config.currency === pairedAsset) ?? null;

                if (
                    maxPrice === null &&
                    (!gatewayConfig || gatewayConfig.paymentGatewayLimit === null)
                ) {
                    return _setRules({
                        asset,
                        pairedAsset,
                        ...staticPrices[`${asset}/USD`],
                        maxPrice: null,
                    });
                }
                if (
                    maxPrice === null &&
                    gatewayConfig &&
                    gatewayConfig.paymentGatewayLimit !== null
                ) {
                    return _setRules({
                        asset,
                        pairedAsset,
                        ...staticPrices[`${asset}/USD`],
                        maxPrice:
                            gatewayConfig.paymentGatewayLimit /
                            gatewayConfig.currencyExchangeRateInUsd,
                    });
                }
                if (
                    maxPrice !== null &&
                    gatewayConfig &&
                    gatewayConfig.currencyExchangeRateInUsd !== null &&
                    gatewayConfig.paymentGatewayLimit === null
                ) {
                    return _setRules({
                        asset,
                        pairedAsset,
                        ...staticPrices[`${asset}/USD`],
                        maxPrice: maxPrice / gatewayConfig.currencyExchangeRateInUsd,
                    });
                } else if (maxPrice || gatewayConfig?.paymentGatewayLimit) {
                    let maxLimit = Math.min(
                        maxPrice ?? gatewayConfig!.paymentGatewayLimit!,
                        gatewayConfig?.paymentGatewayLimit ?? maxPrice!
                    );
                    return _setRules({
                        asset,
                        pairedAsset,
                        ...staticPrices[`${asset}/USD`],
                        maxPrice: maxLimit / (gatewayConfig?.currencyExchangeRateInUsd ?? 1),
                    });
                }
            }
            return _setRules(null);
        },
        [staticPrices, midConfigs]
    );

    return (
        <div className="QuickCoinPage">
            {userInfo?.bQuickCryptoSaleEnabled && showBuySellButtons && (
                <div className="RadioButtons">
                    <RadioButton
                        label={'Buy'}
                        selected={transactionType === 'Buy'}
                        onClick={() => setTransactionType('Buy')}
                    />
                    <RadioButton
                        label={'Sell'}
                        selected={transactionType === 'Sell'}
                        onClick={() => setTransactionType('Sell')}
                    />
                </div>
            )}
            {transactionType === 'Sell' && userInfo?.bQuickCryptoSaleEnabled ? (
                <QuickCoinSell
                    cryptoOptions={cryptoOptions}
                    prices={prices}
                    rules={rules}
                    updateRules={updateRules}
                    onChangeStep={hideBuySellButtons}
                />
            ) : (
                <QuickCoinBuy
                    cryptoOptions={cryptoOptions}
                    prices={prices}
                    rules={rules}
                    updateRules={updateRules}
                    midConfigs={midConfigs}
                    setMidConfigs={setMidConfigs}
                    onChangeStep={hideBuySellButtons}
                />
            )}
        </div>
    );
};

export const handleGenericError = (
    err: any,
    values: any, //validationSchema.fields
    formikHelpers: FormikHelpers<any>,
    setErrMsg?: (msg: string) => void,
    errMsg?: string
) => {
    if (isAxiosErrorHandled(err) && err.response.data.errors) {
        if (
            err.response.data.errors?.some(
                (error) =>
                    error.fieldName === 'ExpYear' ||
                    error.fieldName === 'ExpMonth' ||
                    Object.keys(values).includes(toCamelCase(error.fieldName) as string)
            )
        ) {
            //or filtered.forEach
            err.response.data.errors.forEach((error) => {
                const fieldName = toCamelCase(error.fieldName) as string;
                if (error.fieldName === 'ExpYear' || error.fieldName === 'ExpMonth') {
                    formikHelpers.setFieldError('expiryDate', getErrorMessage(error.messageCode));
                } else if (Object.keys(values).includes(fieldName)) {
                    formikHelpers.setFieldError(fieldName, getErrorMessage(error.messageCode));
                }
            });
        } else {
            if (err.response.data?.errors?.length > 0) {
                const errors = err.response.data.errors;
                setErrMsg &&
                    setErrMsg(
                        errors[0].messageCode === 'Failed' &&
                            (errors[0].fieldName === 'Create_Payment' ||
                                errors[0].fieldName === 'Create_Card')
                            ? 'There may be something incorrect with card details. Please check your entries'
                            : Object.keys(ERROR_CODES).includes(errors[0].messageCode)
                            ? getErrorMessage(errors[0].messageCode)
                            : errMsg ?? 'There is a problem. Please try again later'
                    );
            } else setErrMsg && setErrMsg(getErrorMessage(errMsg ? '' : 'Generic'));
        }
    }
};
