import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { endpoints } from 'endpoints.config';
import './ConnectionsPage.scss';
import { FoldingCube } from 'better-react-spinkit';
import { useTheme } from '@emotion/react';
import api, { ApiResponse, useTable } from 'api';
import { ItemConnection, ItemConnectionModel } from './items/ItemConnection';

import { ModalSelectConnectionAssetAccount } from './modals/ModalSelectConnectionAssetAccount';
import Pagination from 'components/pagination/Pagination';

type Props = {
    back?: () => void;
};

export const AllConnectionsList = forwardRef((props: Props, ref) => {
    const { back } = props;
    const [makePaymentConnection, setMakePaymentConnection] = useState<
        ItemConnectionModel | undefined
    >();
    const { colors } = useTheme();
    const handleMakePayment = useCallback((connection: ItemConnectionModel) => {
        setMakePaymentConnection(connection);
    }, []);

    const transactionsTable = useTable<ItemConnectionModel, any>({
        url: `${endpoints.networkmodule.getNetworkConnections}`,
        bClearFilters: true,
    });

    useImperativeHandle(ref, () => ({
        refreshData: transactionsTable?.reload,
    }));

    return (
        <>
            <div className="list-container-networks">
                {transactionsTable?.loading && (
                    <div className="PageLoader">
                        <FoldingCube color={colors.first} size={80} />
                    </div>
                )}

                {transactionsTable?.data?.details?.listData &&
                transactionsTable?.data?.details?.listData?.length > 0 ? (
                    transactionsTable?.data?.details?.listData.map(
                        (item: ItemConnectionModel, index: number) => (
                            <ItemConnection
                                key={item.receiverId + '_' + index}
                                networkUser={item}
                                showConnectionRequest={(itemClicked: ItemConnectionModel) => {
                                    //setConnectionDetail(itemClicked);
                                }}
                                makePayment={handleMakePayment}
                                refreshList={transactionsTable?.reload}
                            />
                        )
                    )
                ) : transactionsTable?.loading ? (
                    <></>
                ) : (
                    <div className="no-results-message">No results found</div>
                )}
                <Pagination table={transactionsTable} />
            </div>
            {makePaymentConnection && (
                <ModalSelectConnectionAssetAccount
                    closeModal={() => setMakePaymentConnection(undefined)}
                    selectedConnection={makePaymentConnection}
                />
            )}
        </>
    );
});
