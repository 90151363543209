import { PriceInfo } from 'reducers/cryptoPrices';
import { InitProperties as GooglePayProperties } from 'helpers/googlePay';
import * as Yup from 'yup';

export const initialValues: PurchaseDetails = {
    //: FormValues
    cardType: 'New card',
    asset: '',
    price: undefined,
    amount: undefined,
    currency: '',
    bSaveCard: true,
    transactionType: 'Buy',
    //paymentCardsId: 0,
    //cvV2: '',
    quantityType: 'Amount',
    bAcceptedTermsAndConditions: false,
};
export type FormValues = PurchaseDetails; //& SavedCardDetails & NewCardDetails;
export type PurchaseDetails = {
    cardType: 'New card' | 'Saved card' | null;
    asset: string;
    currency: string;
    price?: number;
    amount?: number;
    quantityType: 'Amount' | 'Price';
    bAcceptedTermsAndConditions: boolean;
    bSaveCard?: boolean;
    transactionType: 'Buy' | 'Sell';
};
export type SavedCardDetails = {
    paymentCardsId: number;
    cvV2: string;
};

export enum PaymentFlowType {
    TokenizationIFrame = 'TokenizationIFrame',
    Form = 'Form',
    IFrame = 'IFrame',
}

export type PaymentGatewayMidConfig = {
    bUse3ds: boolean;
    bUseApplePay: boolean;
    bUseGooglePay: boolean;
    bUseTokenization: boolean;
    currency: string;
    googlePayDetails: GooglePayProperties;
    paymentGatewayLimit: number | null;
    currencyExchangeRateInUsd: number;
};

export type InitResponse = {
    paymentFlow: PaymentFlowType;
    encryption: { publicKey: string; keyId: string };
    idempotencyKey: string;
    paymentGatewayMidConfig: PaymentGatewayMidConfig[];
};

export type Rules = PriceInfo & { asset: string; pairedAsset: string };

export const validationSchema = (rules: Rules | null) =>
    Yup.object({
        cardType: Yup.string(),
        asset: Yup.string().required('Please choose a crypto'),
        currency: Yup.string().required('Please choose a currency'),
        amount: Yup.number()
            .required('Please select an amount')
            .min(
                rules?.minAmount ?? 0,
                `You must buy at least ${rules?.minAmount ?? 0} ${rules?.asset}`
            )
            .max(
                rules?.maxAmount ?? Number.MAX_SAFE_INTEGER,
                `You cannot buy more than ${rules?.maxAmount ?? Number.MAX_SAFE_INTEGER} ${
                    rules?.asset
                }`
            ),
        price: Yup.number()
            .required('Please set a price')
            .min(
                rules?.minPrice ?? 0,
                `You must spend at least ${rules?.minPrice ?? 0} ${rules?.pairedAsset}`
            )
            .max(
                rules?.maxPrice ?? Number.MAX_SAFE_INTEGER,
                `You cannot spend more than ${rules?.maxPrice ?? Number.MAX_SAFE_INTEGER} ${
                    rules?.pairedAsset
                }`
            ),
        bAcceptedTermsAndConditions: Yup.boolean()
            .required('You have not accepted terms and conditions')
            .test('tc', 'You have not accepted terms and conditions', function (val) {
                return val === true;
            }),
    });

const FISMaxSellPrice = 1000; // card creation fails for values > $1000

//SELL
export const basketValidationSchema = (rules: Rules | null) =>
    Yup.object({
        asset: Yup.string().required('Please choose a crypto'),
        currency: Yup.string().required('Please choose a currency'),
        amount: Yup.number()
            .required('Please select an amount')
            .min(
                rules?.minAmount ?? 0,
                `You must sell at least ${rules?.minAmount ?? 0} ${rules?.asset}`
            )
            .max(
                rules?.maxAmount ?? Number.MAX_SAFE_INTEGER,
                `You cannot sell more than ${rules?.maxAmount ?? Number.MAX_SAFE_INTEGER} ${
                    rules?.asset
                }`
            ),
        price: Yup.number()
            .required('Please set a price')
            .min(
                rules?.minPrice ?? 0,
                `You must buy at least ${rules?.minPrice ?? 0} ${rules?.pairedAsset}`
            )
            .max(
                Math.min(FISMaxSellPrice, rules?.maxPrice ?? Number.MAX_SAFE_INTEGER),
                `You cannot buy more than ${Math.min(
                    FISMaxSellPrice,
                    rules?.maxPrice ?? Number.MAX_SAFE_INTEGER
                )} ${rules?.pairedAsset}`
            ),
        bAcceptedTermsAndConditions: Yup.boolean()
            .required('You have not accepted terms and conditions')
            .test('tc', 'You have not accepted terms and conditions', function (val) {
                return val === true;
            }),
    });

export const detailsValidationSchema = Yup.object({
    // deliveryAddress: Yup.object({
    //     addressLine1: Yup.string().required('Please enter your address'),
    //     addressLine2: Yup.string(),
    //     townCity: Yup.string().required('Please enter your town/city'),
    //     state: Yup.string().required('Please enter your state'),
    //     countryCode: Yup.string().required('Please enter your country'),
    //     postcode: Yup.string().required('Please enter your zip code'),
    // }),
    dateOfBirth: Yup.mixed().required('Please enter your date of birth'),
    socialSecurityNumber: Yup.string().required('Please enter your social security number'),
});

export const initialBasketValues: SaleDetails = {
    asset: '',
    currency: '',
    price: undefined,
    amount: undefined,
    bAcceptedTermsAndConditions: false,
    deliveryAddress: {
        addressLine1: '',
        addressLine2: '',
        townCity: '',
        state: '',
        country: '',
    },
    dateOfBirth: '',
    socialSecurityNumber: '',
    quantityType: 'Amount',
};

type DeliveryAddress = {
    addressLine1: string;
    addressLine2: string;
    townCity: string;
    state: string;
    country: string;
};

export type SaleDetails = {
    asset: string;
    currency: string;
    price?: number;
    amount?: number;
    bAcceptedTermsAndConditions: boolean;
    deliveryAddress: DeliveryAddress;
    dateOfBirth: string;
    socialSecurityNumber: string;
    quantityType: 'Amount' | 'Price';
};
